<template>
  <div>
    <van-nav-bar title="拓客申请结果" left-arrow :fixed="true" :z-index="999" @click-left="back" />

    <div style="padding-top:46px;">
      <van-row>
        <van-col span="12" class="ls">门店名称</van-col>
        <van-col span="12" class="rs">
          <!-- <div class="dd" style="color: #ee0a24;">待审核</div> -->
          <div class="dd" style="color: #4fc08d">{{nameID.name}}</div>
        </van-col>
      </van-row>
      <van-row>
        <van-col span="12" class="ls">工时提成</van-col>
        <van-col span="12" class="rs">{{list.percentageTimeRate}}%</van-col>
      </van-row>
      <van-row>
        <van-col span="12" class="ls">一般配件提成</van-col>
        <van-col span="12" class="rs">{{list.percentagePartRate}}%</van-col>
      </van-row>
      <van-row>
        <van-col span="12" class="ls">特殊配件提成</van-col>
      </van-row>
      <van-row v-for="item in list.tokerPartPercentageDOS" :key="item">
        <van-col span="12" class="ls2">{{item.partName}}</van-col>
        <van-col span="12" class="rs">{{item.percentage}}%</van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import userwei from "../../api/user";
import QRCode from "qrcodejs2";
export default {
  components: {},
  data() {
    return {
      list: [],
      nameID: {},
      value: ""
    };
  },
  methods: {
    onLoad() {
      console.log(this.$route.params);
      let data ={
        tokerId:this.$route.params.tokerId,
        gid:this.$route.params.gid,
      }
      userwei
        .getResult(data)
        .then(e => {
          // loading.clear();
          this.list = e.data;
          console.log(e.data)
        })
        .catch(() => loading.clear());
    },
    back() {
      this.$router.go(-1);
    }
  },
  created() {
    this.nameID = this.$route.params;
    this.onLoad();
  }
};
</script>

<style lang="less" scoped>
div {
  color: #323233;
}
.views {
  /deep/ div:nth-of-type(2) span {
    color: #1989fa;
  }
}
.sumit {
  width: 100%;
  padding: 10px;
  position: absolute;
  bottom: 0;
}
.van-button {
  width: 50%;
}
.van-row {
  font-size: 13px;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #ebedf0;
  overflow: hidden;
}
.ls {
  padding-left: 20px;
}
.ls2 {
  padding-left: 40px;
}
.rs {
  height: 45px;
  overflow: hidden;
  text-align: right;
  padding-right: 20px;
}
.dd {
  // display: inline-block;
  float: right;
}
</style>
